import Turbolinks from 'turbolinks'
import 'lazysizes'

import { 
	initClickTriggers,
	initAutoClosableElements,
	initIntersectionObserver,	
	handleGnbOnScroll,
	throttle,
	initAttachments
} from '@utils'

import * as clickHandlers from '@utils/clickHandlers'
import { initTicker } from '@utils/ticker'

Turbolinks.start()

document.addEventListener('turbolinks:load', async () => {
	if (window.innerWidth > 600) document.addEventListener('scroll', () => throttle(handleGnbOnScroll))

	initClickTriggers(clickHandlers)

	if (location.pathname === '/') {
		initTicker()
	}

	initAutoClosableElements()
	initAttachments()

	const infiniteScrollList = document.getElementById('infinite-scroll-list')
	if (infiniteScrollList) initIntersectionObserver(infiniteScrollList) 
})
