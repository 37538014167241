import { postData, openWindow } from '@utils/request'

export const showMobileGnb = (ev) => {
	ev.stopPropagation()

	const mobileGnb = ev.target.parentElement.querySelector('.mobile-gnb')
	if (mobileGnb.classList.contains('hidden')) {
		mobileGnb.classList.remove('hidden')
		document.getElementById('js-overlay').classList.remove('hidden')
		document.body.classList.add('overflow-hidden')
	} else {
		mobileGnb.classList.add('hidden')
		document.getElementById('js-overlay').classList.add('hidden')
		document.body.classList.remove('overflow-hidden')
	}
}

export const showSearchModal = (ev) => {
	ev.stopPropagation()

	const searchModal = ev.target.closest('.header').querySelector('.search-modal')
	const searchInput = searchModal.querySelector('.search-input')

	document.getElementById('js-overlay').classList.remove('hidden')
	document.body.classList.add('overflow-hidden')

	searchModal.classList.remove('hidden')
	searchInput.focus()
}

export const toggleClass = (selectors, _class) => {
	console.log("zzz")
	selectors.split(';').forEach((selector) => {
		document.querySelector(selector).classList.toggle(_class);
	});
}



export const hideSearchModal = (ev) => {
	const searchModal = ev.target.closest('.header').querySelector('.search-modal')
	document.getElementById('js-overlay').classList.add('hidden')

	document.body.classList.remove('overflow-hidden')

	searchModal.classList.add('hidden')
}

const showValidationMessage = (messageHolder, message) => {
	messageHolder.classList.remove('hidden')
	messageHolder.textContent = message
}

export const shareArticle = (ev) => {
	ev.target.closest('.sharing-modal').classList.add('hidden')

	const platform = ev.target.getAttribute('data-platform')

	switch (platform) {
		case 'facebook': {
			const url = encodeURIComponent(document.URL)
			openWindow(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 500, 500)

			break
		}
		case 'x': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://twitter.com/intent/tweet?url=${url}&text=${title}`, 500, 500)

			break
		}
		case 'linkedin': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`, 500, 500)

			break
		}
		case 'telegram': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://t.me/share?url=${url}&text=${title}`, 500, 500)

			break
		}
		case 'url': {
			if (navigator.clipboard) {
				(async() => {
					await navigator.clipboard.writeText(location.href)
					alert('URL이 복사되었습니다.')
				})()
			}

			break
		}
	}
}

export const showSharingModal = (ev) => {
	document.getElementById('js-overlay').classList.remove('hidden')
	document.body.classList.add('overflow-hidden')

	ev.target.parentElement.querySelector('.sharing-modal').classList.remove('hidden')
}

export const printArticle = () => window.print()
