export default {
  element: null,
  showTimeout: null,
  minDisplayTime: 500,

  getElement: function () {
    if (!this.element) this.element = document.getElementById('loader')
    return this.element
  },

  show: function (delay = 300) {
    this.clearShowTimeout()
    this.showTimeout = setTimeout(() => {
      const loader = this.getElement()
      if (loader) {
        loader.classList.remove('invisible')
        this.visibleSince = Date.now()
      }
    }, delay)
  },

  hide: function() {
    this.clearShowTimeout()
    const loader = this.getElement()
    if (loader && !loader.classList.contains('invisible')) {
      const timeVisible = Date.now() - this.visibleSince
      if (timeVisible < this.minDisplayTime) {
        setTimeout(() => {
          loader.classList.add('invisible')
        }, this.minDisplayTime - timeVisible)
      } else {
        loader.classList.add('invisible')
      }
    }
  },

  clearShowTimeout: function() {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout)
      this.showTimeout = null
    }
  },

  reset: function() {
    this.element = null
    this.clearShowTimeout()
  }
}
