import Loader from '@utils/loader'

export const getData = async (url, showLoader = true) => {
	if (showLoader) Loader.show()

	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error('')
		}
		return await response.json()
	} catch(error) {
		throw error
	} finally {
		if (showLoader) Loader.hide()
	}
}

export const postData = async (url, data) => {
	Loader.show()

	try {
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
			}
		})

		if (response.ok) {
			if (response.status === 204) {
				return true 
			}

			return await response.json()
		} else if (response.status === 409) {
			const errorData = await response.json()
			throw { status: response.status, message: errorData.errorMessage }
		} else {
			throw new Error('error')
		}
	} catch(error) {
		throw error
	} finally {
		Loader.hide()
	}
}

export const makeRequest = async (url, { method = 'GET', body = null } = {}) => {
	Loader.show()

	const options = {
		method: method.toUpperCase(),
		headers: {
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest'
		}
	}

	if (options.method !== 'GET') {
		options.headers['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content')
	}

	if (body) {
		options.body = JSON.stringify(body)
	}

	try {
		const response = await fetch(url, options)
		if (response.ok) {
			if (response.status === 204) {
				return true 
			}
			const responseData = await response.json()
		} else {
			const responseData = await response.json()
			return {
				errorMessage: responseData.errorMessage
			}
		}
	} catch (error) {
		console.log(error)
	} finally {
		Loader.hide()
	}

/*
	try {
		const response = await fetch(url, options)
		if (!response.ok) {
			const responseData = await response.json()
			throw new Error(responseData.errorMessage)
		}

		if (response.status === 204) return true

		return await response.json()
	} catch (error) {
		//throw error
		//return error
		return false 
	} finally {
		Loader.hide()
	}
*/
}

export const submitForm = async (form, actionUrl = form.action) => {
	Loader.show()

	const url = new URL(actionUrl)
	const formData = new FormData(form)
	const searchParams = new URLSearchParams(formData)

	const fetchOptions = {
		method: form.method
		//signal: AbortSignal.timeout(3000)
	}

	if (form.method.toLowerCase() === 'post') {
		if (form.enctype === 'multipart/form-data') {
			fetchOptions.body = formData
		} else {
			fetchOptions.body = searchParams
		}
	} else {
		url.search = searchParams
	}

	try {
		const response = await fetch(url, fetchOptions)
		if (!response.ok) {
			const responseData = await response.json()
			throw new Error(responseData.errorMessage)
		}

		return await response.json()
	} catch (error) {
		console.log(error)
		throw error
	} finally {
		Loader.hide()
	}
}

export const uploadFile = async (url, file) => {
	Loader.show()

  const formData = new FormData()
  formData.append('file', file)

	try {
		const response = await fetch(url, {
			method: 'POST',
			body: formData
		})

		return await response.json()
	} catch(error) {
		console.log(error)
	} finally {
		Loader.hide()
	}
}

export const downloadFile = async (requestUrl) => {
	Loader.show()

	try {
		const response = await fetch(requestUrl)
		const contentDisposition = response.headers.get('Content-Disposition')

		let filename = ''
		const filenameRegex = /filename\*=UTF-8''([^;]*)/
		const match = contentDisposition.match(filenameRegex)
		if (match && match[1]) {
			filename = decodeURIComponent(match[1])
		}

		const blob = await response.blob()
		const url = window.URL.createObjectURL(blob)
		const a = document.createElement('a')

		a.href = url
		a.download = filename

		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)

		window.URL.revokeObjectURL(url)
	} catch (error) {
		console.log(error)
	} finally {
		Loader.hide()
	}
}

export const previewFile = async (requestUrl) => {
	const response = await makeRequest(requestUrl, 'GET')
	openWindow(response.fileUrl, screen.availWidth, screen.availHeight)
}

export const openWindow = (url, w = 600, h = 600) => {
	const left = window.top.outerWidth / 2 + window.top.screenX - ( w / 2)
	const __top = window.top.outerHeight / 2 + window.top.screenY - ( h / 2)
	const popupWindow = window.open(url, url, `scrollbars=yes, width=${w}, height=${h}, top=${__top}, left=${left}`)

	if (window.focus) popupWindow.focus()

	return popupWindow
}
