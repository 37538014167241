export const initTicker = () => {
  const tickerContainer = document.querySelector('.ticker-container')
  const ticker = tickerContainer.querySelector('.ticker')
  const tickerItems = ticker.querySelectorAll('.ticker-item')
  
  const leftButton = tickerContainer.querySelector('.left-button')
  const rightButton = tickerContainer.querySelector('.right-button')
  
  const itemHeight = tickerItems[0].offsetHeight
  let currentIndex = 0
  let lastTime = 0
  let elapsed = 0
  const interval = 3000
  let isPaused = false

  const firstItemClone = tickerItems[0].cloneNode(true)
  ticker.appendChild(firstItemClone)

  const animate = (currentTime) => {
    if (lastTime === 0) {
      lastTime = currentTime
    }

    const deltaTime = currentTime - lastTime
    
    if (!isPaused) {
      elapsed += deltaTime

      if (elapsed >= interval) {
        moveNext()
        elapsed = 0
      }
    }

    lastTime = currentTime
    requestAnimationFrame(animate)
  }

  const moveNext = () => {
    currentIndex++
    updateTicker()
  }

  const movePrev = () => {
    currentIndex--
    if (currentIndex < 0) {
      currentIndex = tickerItems.length - 1
      ticker.style.transition = 'none'
      ticker.style.transform = `translateY(-${currentIndex * itemHeight}px)`
      ticker.offsetHeight
    }
    updateTicker()
  }

  const updateTicker = () => {
    const totalItems = tickerItems.length + 1

    if (currentIndex >= totalItems) {
      currentIndex = 0
      ticker.style.transition = 'none'
      ticker.style.transform = 'translateY(0)'
      ticker.offsetHeight
    }
    
    ticker.style.transition = 'transform 0.3s ease'
    ticker.style.transform = `translateY(-${currentIndex * itemHeight}px)`

    tickerItems.forEach((item, index) => {
      item.classList.toggle('active', index === currentIndex % tickerItems.length)
    })
  }


  tickerItems[0].classList.add('active')

  requestAnimationFrame(animate)

  tickerContainer.addEventListener('mouseenter', () => {
    isPaused = true
  })

  tickerContainer.addEventListener('mouseleave', () => {
    isPaused = false
  })


  leftButton.addEventListener('click', () => {
    movePrev()
    elapsed = 0
  })

  rightButton.addEventListener('click', () => {
    moveNext()
    elapsed = 0
  })
}
